const octoTheme =  {
  color: {
    red: "rgb(255, 69, 58)",
    orange: "rgb(255, 159, 10)",
    yellow: "rgb(255, 214, 10)",
    green: "rgb(48, 209, 88)",
    mint: "rgb(99, 230, 226)",
    teal: "rgb(64, 200, 224)",
    cyan: "rgb(100, 210, 255)",
    blue: "rgb(10, 132, 255)",
    indigo: "rgb(94, 92, 230)",
    purple: "rgb(191, 90, 242)",
    pink: "rgb(255, 55, 95)",
    brown: "rgb(172, 142, 104)",
    white: "rgb(255, 255, 255)",
    gray1: "rgb(242, 242, 247)",
    gray2: "rgb(229, 229, 234)",
    gray3: "rgb(209, 209, 214)",
    gray4: "rgb(199, 199, 204)",
    gray5: "rgb(174, 174, 178)",
    gray6: "rgb(142, 142, 147)",
    gray7: "rgb(99, 99, 102)",
    gray8: "rgb(72, 72, 74)",
    gray9: "rgb(58, 58, 60)",
    gray10: "rgb(44, 44, 46)",
    gray11: "rgb(28, 28, 30)",
    gray12: "rgb(14, 14, 16)",
    black: "rgb(0, 0, 0)",
    transparent: "transparent"
  }
};


export { octoTheme }
