import React, { memo } from "react";
import { Handle, Position } from "reactflow";

const CustomHandle = memo(({ data }) => {
  const renderHandle = (type, position) => {
    if (type) {
      return <Handle type={type} position={position} id={position} />;
    } else {
      return <></>
    }
  };

  return (
    <>
      {renderHandle(data.top, Position.Top)}
      {renderHandle(data.right, Position.Right)}
      {renderHandle(data.bottom, Position.Bottom)}
      {renderHandle(data.left, Position.Left)}
    </>
  );
});

export { CustomHandle };
