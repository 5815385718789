import { Controller } from "@hotwired/stimulus";
import { Collapse }  from "bootstrap";

async function visitEnd(data) {
  if (document.head.querySelector("meta[name=env]").content == "development") {
    console.log("Visit end: ", data);
  } else {
    try {
      const response = await fetch("/guide_visits/visit_end", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
    } catch (error) {
      console.error("Error: ", error);
    }
  }
}
export default class extends Controller {
  connect() {
    const desktop = window.innerWidth > 767;
    const bsCollapse = new Collapse("#guideMenu", {
      toggle: desktop
    });

    const data = { guide: this.element.getAttribute("data-guide") };
    window.addEventListener("beforeunload", (event) => {
      visitEnd(data)
    });
  }

  disconnect() {
    const data = { guide: this.element.getAttribute("data-guide") };
    visitEnd(data);
  }
}
