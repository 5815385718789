import { Turbo } from "@hotwired/turbo-rails";
import React from "react";
import mount from "./utils/mount";
import "./controllers"
import { Tooltip } from "bootstrap";

import { OctoApp } from "./components/OctoApp";

import "trix"
import "@rails/actiontext"


document.addEventListener('turbo:load', () => {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));
});

mount({
  OctoApp
});
