import React, { memo } from "react";
import styled from "styled-components";
import { CustomHandle } from "./CustomHandle";
import { StyledNode } from "./StyledNode";

const Node = styled(StyledNode)``;

const ValveNode = memo(({ data, selected }) => {
  return (
    <Node selected={selected} className={data.className} $relay={data.relay}>
      <div className="label">{data.label}</div>
      <CustomHandle data={data} />
    </Node>
  );
});

export { ValveNode };
