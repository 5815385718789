import { ReactFlow, Background, MiniMap, Controls, Panel } from "reactflow";
import styled from "styled-components";

const StyledReactFlow = styled(ReactFlow)`
  background-color: ${(props) => props.theme.color.gray11};

  .react-flow__attribution {
    background: none;
  }
`;

const StyledBackground = styled(Background)``;

const StyledMiniMap = styled(MiniMap)``;

const StyledControls = styled(Controls)`
  background-color: ${(props) => props.theme.color.white};
`;

const StyledPanel = styled(Panel)`
  button {
    border: 3px solid ${(props) => props.theme.color.gray5};;
  }
`;

const StyledWrapper = styled.div`
  width: 1200px;
  height: 1200px;
`;

export { StyledReactFlow, StyledMiniMap, StyledControls, StyledBackground, StyledPanel, StyledWrapper };
