import React, { memo } from "react";
import styled from "styled-components";
import { CustomHandle } from "./CustomHandle";

const Node = styled.div`
  min-height: 10px;
  min-width: 10px;
  border-radius: 10px;
  border: 2px solid
    ${(props) =>
      props.selected ? props.theme.color.white : props.theme.color.transparent};


  &.cold {
    background: ${(props) => props.theme.color.blue};
    .react-flow__handle {
      background: ${(props) => props.theme.color.blue};
    }
  }

  &.hot {
    background: ${(props) => props.theme.color.red};
    .react-flow__handle {
      background: ${(props) => props.theme.color.red};
    }
  }

  &.warm {
    background: ${(props) => props.theme.color.purple};
    .react-flow__handle {
      background: ${(props) => props.theme.color.purple};
    }
  }

  &.condensate {
    background: ${(props) => props.theme.color.gray1};
    .react-flow__handle {
      background: ${(props) => props.theme.color.gray1};
    }
  }

  &.propane {
    background: ${(props) => props.theme.color.yelow};
    .react-flow__handle {
      background: ${(props) => props.theme.color.yellow};
    }
  }

  .react-flow__handle {
    opacity: 0;
  }
`;

const TeeNode = memo(({ data, selected }) => {
  return (
    <Node selected={selected} className={data.className}>
      <CustomHandle data={data} />
    </Node>
  );
});

export { TeeNode };
