import React, { useCallback } from "react";
import styled, { ThemeProvider } from "styled-components";
import { octoTheme } from "../utils/theme";
import { create } from "zustand";
import { useShallow } from 'zustand/react/shallow'

import {
  Position,
  addEdge,
  SelectionMode,
  applyNodeChanges,
  applyEdgeChanges
} from "reactflow";
import { StyledReactFlow, StyledControls, StyledBackground, StyledPanel, StyledWrapper } from "./reactflow/StyledReactFlow";
import { PumpNode } from "./reactflow/PumpNode";
import { TeeNode } from "./reactflow/TeeNode";
import { ValveNode } from "./reactflow/ValveNode";
import { FlushValveNode } from "./reactflow/FlushValveNode";


import "reactflow/dist/style.css";

// remove
function BearCounter() {
  const bears = useStore((state) => state.bears)
  return <h1>{bears} around here ...</h1>
}

// remove
function Controls2() {
  const increasePopulation = useStore((state) => state.increasePopulation)
  return <button onClick={increasePopulation}>one up</button>
}


const nodeTypes = { pumpNode: PumpNode, teeNode: TeeNode, valveNode: ValveNode, flushValveNode: FlushValveNode };


const panOnDrag = [1, 2];

const coldWater = { strokeWidth: 2, stroke: octoTheme.color.blue };
const hotWater = { strokeWidth: 2, stroke: octoTheme.color.red };
const warmWater = { strokeWidth: 2, stroke: octoTheme.color.purple };

const initialNodes = [
  {
    id: "heater",
    position: { x: 20, y: 20 },
    data: { label: "Heater" },
    sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },
  {
    id: "coldFlush",
    position: { x: 220, y: 20 },
    data: { label: "Cold Flush", left: "source", right: "target", relay: true, className: "cold" },
    type: "flushValveNode",
  },
  {
    id: "coldHeater",
    position: { x: 420, y: 20 },
    data: { label: "Cold Heater", left: "source", right: "target", relay: true, className: "cold" },
    type: "valveNode",
  },
  {
    id: "coldShower",
    position: { x: 720, y: 20 },
    data: { label: "Cold Shower", left: "target", right: "source", relay: false, className: "cold" },
    type: "valveNode",
  },
  {
    id: "showerFaucet",
    position: { x: 920, y: 20 },
    data: { label: "Shower Faucet" },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
  {
    id: "zincAnode",
    position: { x: 420, y: 120 },
    data: { label: "Zinc Anode" },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
  {
    id: "meshPrefilter",
    position: { x: 220, y: 120 },
    data: { label: "Mesh Prefilter" },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
  {
    id: "circulationPump",
    position: { x: 20, y: 120 },
    data: { label: "Circulation Pump" },
    sourcePosition: Position.Right,
    targetPosition: Position.Bottom,
  },
  {
    id: "pumpValve",
    position: { x: 20, y: 220 },
    data: { label: "Pump Valve", left: "source", bottom: "target", relay: false, className: "cold" },
    type: "valveNode"
  },
  {
    id: "bypassValve",
    position: { x: 125, y: 320 },
    data: { label: "Bypass Valve" },
    sourcePosition: Position.Top,
    targetPosition: Position.Left,
  },
  {
    id: "coldThermostatic",
    position: { x: 720, y: 120 },
    data: { label: "Cold Thermostatic" },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
  {
    id: "zincAnodeTee",
    type: "teeNode",
    position: { x: 620, y: 50 },
    data: { left: "source", bottom: "target", right: "source", top: "source", className: "cold" },
  },
  {
    id: "thermostaticValve",
    position: { x: 720, y: 220 },
    data: { label: "Thermostatic Valve" },
    sourcePosition: Position.Left,
    targetPosition: Position.Right,
  },
];

const initialEdges = [
  {
    id: "1",
    source: "coldFlush",
    target: "heater",
    type: "smoothstep",
    style: coldWater,
  },
  {
    id: "2",
    source: "coldHeater",
    target: "coldFlush",
    type: "smoothstep",
    style: coldWater,
  },
  {
    id: "4",
    source: "coldShower",
    target: "showerFaucet",
    type: "smoothstep",
    style: coldWater,
  },
  {
    id: "5",
    source: "meshPrefilter",
    target: "zincAnode",
    type: "smoothstep",
    style: coldWater,
  },
  {
    id: "6",
    source: "zincAnode",
    target: "zincAnodeTee",
    type: "smoothstep",
    style: coldWater,
  },
  {
    id: "7",
    source: "circulationPump",
    target: "meshPrefilter",
    type: "smoothstep",
    style: coldWater,
  },
  {
    id: "9",
    source: "pumpValve",
    target: "bypassValve",
    type: "smoothstep",
    style: coldWater,
  },
  {
    id: "10",
    source: "bypassValve",
    target: "meshPrefilter",
    type: "smoothstep",
    style: coldWater,
  },
  {
    id: "11",
    source: "zincAnodeTee",
    sourceHandle: "left",
    target: "coldHeater",
    type: "smoothstep",
    style: coldWater,
  },
  {
    id: "12",
    source: "zincAnodeTee",
    sourceHandle: "right",
    target: "coldShower",
    type: "smoothstep",
    style: coldWater,
  },
  {
    id: "13",
    source: "zincAnodeTee",
    sourceHandle: "top",
    target: "coldThermostatic",
    type: "smoothstep",
    style: coldWater,
  },
  {
    id: "14",
    source: "coldThermostatic",
    target: "thermostaticValve",
    type: "smoothstep",
    style: coldWater,
  },
];



const useStore = create((set, get) => ({
  nodes: initialNodes,
  edges: initialEdges,
  bears: 5,
  onNodesChange: changes => {
    set({
      nodes: applyNodeChanges(changes, get().nodes)
    })
  },
  onEdgesChange: changes => {
    set({
      edges: applyEdgeChanges(changes, get().edges)
    })
  },
  onConnect: connection => {
    set({
      edges: addEdge(connection, get().edges)
    })
  },
  increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),

  updateNodeColor: (nodeId, color) => {
    console.debug(nodeId);
    set({
      nodes: get().nodes.map(node => {
        if (node.id === nodeId) {
          // it's important to create a new object here, to inform React Flow about the changes
          console.debug('set style')
          node.style = { ...node.style, background: color }
        }

        return node
      })
    })
  },
  fetch: async (pond) => {
    const response = await fetch(pond)
    console.debug(response);
    set({ bears: await response.json() })
  },
}));

const selector = state => ({
  nodes: state.nodes,
  edges: state.edges,
  bears: state.bears,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
})


const Button = styled.button`
  background: transparent;
  border-radius: 3px;
  border: 2px solid #BF4F74;
  color: ${(props) => props.theme.color.red};
  margin: 0 1em;
  padding: 0.25em 1em;
`

const OctoApp = () => {
  const { nodes, edges, onNodesChange, onEdgesChange } = useStore(
    useShallow(selector),
  )

  const updateNodeColor = useStore((s) => s.updateNodeColor);
  const onFetch = useStore((s) => s.fetch)

  return (
    <ThemeProvider theme={octoTheme}>
      <h1> Here</h1>
      <BearCounter />
      <Controls2 />
      <Button>Themed Button</Button>
      <StyledWrapper>
        <StyledReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          panOnScroll
          selectionOnDrag
          panOnDrag={panOnDrag}
          selectionMode={SelectionMode.Partial}
        >
          <StyledControls />
          <StyledBackground variant="dots" gap={20} size={1} color="rgb(142, 142, 147)" />
          <StyledPanel position="top-right">
            <button onClick={onFetch}>Fetch</button>
            <button onClick={() => updateNodeColor("coldHeater", "#ff0000")}>Color</button>
          </StyledPanel>
        </StyledReactFlow>
      </StyledWrapper>
    </ThemeProvider>
  );
};

export { OctoApp };
