import React, { memo } from "react";
import styled from "styled-components";
import { CustomHandle } from "./CustomHandle";

const Node = styled.div`
  padding: 10px 10px 10px 10px
  border-radius: 5px;
  background: ${(props) => props.theme.color.gray1};
  color: ${(props) => props.theme.color.gray12};
  border: 2px solid
    ${(props) =>
      props.selected ? props.theme.color.pink : props.theme.color.gray8};

  .react-flow__handle {
    background: ${(props) => props.theme.color.gray4};
    width: 8px;
    height: 8px;
    border-radius: 3px;
  }
`;

const PumpNode = memo(({ data, selected }) => {
  return (
    <Node selected={selected}>
      <div>
        <strong>{data.label}</strong>
      </div>
      <CustomHandle data={data} />
    </Node>
  );
});

export { PumpNode };
