import styled from "styled-components";

const StyledNode = styled.div`
padding: 10px 10px 10px 10px;
border-radius: 5px;
background: ${(props) => props.theme.color.gray1};
color: ${(props) => props.theme.color.gray12};
border: 2px solid
  ${(props) =>
    props.selected ? props.theme.color.pink : props.theme.color.gray8};

.react-flow__handle {
  background: ${(props) => props.theme.color.gray4};
  width: 8px;
  height: 8px;
  border: none;
}

&.cold {
  border-color: ${(props) =>
    props.selected ? props.theme.color.pink : props.theme.color.blue};
  background-color: ${(props) =>
    props.$relay ? props.theme.color.blue : props.theme.color.white};
  .react-flow__handle {
    background: ${(props) => props.theme.color.blue};
  }
}

&.hot {
  background: ${(props) => props.theme.color.red};
  .react-flow__handle {
    background: ${(props) => props.theme.color.red};
  }
}

&.warm {
  background: ${(props) => props.theme.color.purple};
  .react-flow__handle {
    background: ${(props) => props.theme.color.purple};
  }
}

&.condensate {
  background: ${(props) => props.theme.color.gray1};
  .react-flow__handle {
    background: ${(props) => props.theme.color.gray1};
  }
}

&.propane {
  background: ${(props) => props.theme.color.yelow};
  .react-flow__handle {
    background: ${(props) => props.theme.color.yellow};
  }
}

`;

export { StyledNode };
